import { DurationObjectUnits } from 'luxon';
import { SUPPORTED_STATE, InsuranceProvider, InsuranceProvider_FullName } from './global';
import { WT_SKILL } from './authentication';

// Deprecated remove in OWEB-1046, use ReprensentativeProfile instead
export type AvailableAdvocate = {
  name: string;
  licensedStates: SUPPORTED_STATE[];
  email: string;
};

export enum CALL_TRANSFER_STATUS {
  PENDING = 'PENDING', // When a partner wants to transfer a call to us but we did not accept or refuse yet.
  INCOMING = 'INCOMING', // When a partner wants to transfer a call to us and we accepted it, we will receive it within 45 seconds.
  COMPLETED = 'COMPLETED', // When transfer was successfully handover, not all partner can do that.
  REJECTED = 'REJECTED', // When we refuse the transfer.
  FAILED = 'FAILED', // When the transfer failed.
  ROUTING = 'ROUTING', // When reached TD but not picked up yet.
  NOT_CONNNECTED = 'NOT_CONNNECTED', // When the call was not connected.
}

export enum WT_CALL_TYPE {
  WARM_TRANSFER = 'WARM_TRANSFER',
  INBOUND = 'INBOUND',
}

export enum WT_CALL_TRANSFER_STATUS_REASON {
  ABANDONNED = 'call_abandoned',
  NOT_MATCH = 'not_match',
  NO_MATCH = 'no_match',
  TIMEOUT = 'time_limit_reached',
  MISSED = 'call_missed',
  VOICEMAIL = 'leave_vm',
  UPSTREAM_ERROR = 'upstream_error',
  NO_ANSWER = 'call_no_answer',
  ANSWERED = 'call_answered',
  FINISHED = 'call_finished',
}

export const WTE_HISTORY_STATUSES = [CALL_TRANSFER_STATUS.COMPLETED, CALL_TRANSFER_STATUS.FAILED];

export interface WarmTransferEvent {
  apiReferenceUuid: string;
  stateFromAd: SUPPORTED_STATE;
  leadSource: string;
  createdOn: Date;
  timestamp: Date;
  warmTransferSkill: WT_SKILL;
  availableAgentsForState: number;
  callTransferStatus: CALL_TRANSFER_STATUS;
  ani: string;
  country: string;
  eventSource: string;
  pendingWarmLeadsForState: number;
  availability: string;
  availableAgents: number;
  uuid: string;
  version: string;
  agentsOnCall: number;
  ongoingWarmLeadsForState: number;
  ongoingWarmLeads: number;
  phone: string;
  agentsOnCallForState: number;
  pendingWarmLeads: number;
  status: string;
  leadExternalIdentifier: string;
  droppableCallsForState: number;
  droppableCalls: number;
  eventVersion: string;
  reservedAdvocate?: string | null;
  callLogExternalId: string;
  callType: string;
  callTransferStatusReason: string;
  reservationEndTime?: Date | null;
  isReserved: boolean;
  partnerId: string;
}

export type WarmTransferRecord =
  WarmTransferEvent; /* here for legacy now, there are no difference between the two and we should simply use WarmTransferEvent*/

export enum WarmTransferAdvocateState {
  READY = 'ready',
  RELAX = 'relax',
}

export interface PrioritizedLeadRecord {
  recordType: PriorityLeadRecordType;
  recordDetails: PrioritizedLeadRecordDetails;
}
export enum PriorityLeadRecordType {
  LEAD = 'lead',
}

export interface PrioritizedLeadRecordDetails {
  carrierFromAd: InsuranceProvider_FullName;
  name: string;
  externalIdentifier: string;
  stateFromAd: SUPPORTED_STATE;
  createdOn: Date;
  productFromAd: string;
}

export interface PrioritizedLeadCalculated extends PrioritizedLeadRecordDetails {
  timeElapsed?: DurationObjectUnits;
  remainingTime?: DurationObjectUnits;
  isInGoldenTime?: boolean;
  listOfLeadsNotificationSent?: string[];
}

export enum LiveContactEventStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  AFTER_CALL_WORK = 'ACW',
  CONNECTING = 'CONNECTING',
  QUEUE = 'QUEUED',
  RINGING = 'RINGING',
}

export enum TD_CHANNEL_DIRECTION {
  IN = 'VOICE:IN',
  OUT = 'VOICE:OUT',
}

export interface LiveContactEvent {
  user_name: string | null;
  tags: string[];
  queue: string[];
  status: LiveContactEventStatus;
  started_at: string; // ISO DAte
  direction: string;
  contact_number: string /* Matches the ani of a WTE if you add the indicator (in our case in North America +1)*/;
  contact_info: string;
  channel_type: string;
  channel_direction_type: TD_CHANNEL_DIRECTION;
}

export interface WtReservation {
  leadReferenceUuid: string;
  reservedAdvocate: string;
  reservationEndTime: Date;
  leadId: string;
  leadSource: string;
  ani: string;
  partnerId?: string;
}

export interface EmergencyWtRequest {
  id: string;
  leadId: string;
  ani: string;
  owner: string;
  createdOn: Date;
  expiresAt: Date;
  terminated: boolean;
  terminatedAt: Date | null;
  stateFromAd: SUPPORTED_STATE;
  linkedWtReservationId: string | null;
  firstReservedAdvocate: string | null;
}
