import { SUPPORTED_STATE } from './global';
import { DateTime } from 'luxon';
import { REPRESENTATIVE_LEVEL } from './advocate';

export interface O8UserBaseInfo {
  email: string;
  name: string;
  picture: string;
  given_name: string;
  family_name: string;
  roles: APP_ROLES[];
  permissions: APP_PERMS[];
}

export type O8UserExtraInfo = UserProfile;

export type O8User = O8UserBaseInfo & O8UserExtraInfo;

export const WRITE_PERMISSION_PREFIX = 'write:';
export const READ_PERMISSION_PREFIX = 'read:';
export const ALL_PERMISSION_SUFFIX = ':all';

export enum APP_PERMS {
  CONFIGURATION_ARC_WRITE = `${WRITE_PERMISSION_PREFIX}arc-configuration`,
  USER_PROFILE_READ_ALL = `${READ_PERMISSION_PREFIX}user-profile${ALL_PERMISSION_SUFFIX}`,
  USER_PROFILE_READ = `${READ_PERMISSION_PREFIX}user-profile`,
  USER_PROFILE_WRITE = `${WRITE_PERMISSION_PREFIX}user-profile`,
  USER_PROFILE_WRITE_ALL = `${WRITE_PERMISSION_PREFIX}user-profile${ALL_PERMISSION_SUFFIX}`,
  CALL_REVIEW_WRITE = `${WRITE_PERMISSION_PREFIX}call-review`,
  CALL_REVIEW_READ = `${READ_PERMISSION_PREFIX}call-review`,
  CALL_REVIEW_READ_ALL = `${READ_PERMISSION_PREFIX}call-review${ALL_PERMISSION_SUFFIX}`,
  ADVOCATE_CLICK_RECOMMENDED_TASK = `${WRITE_PERMISSION_PREFIX}advocate-click-recommended-task`,
  ARC_FILTERED_VIEW_WRITE = `${WRITE_PERMISSION_PREFIX}arc-filtered-view`,
  ADVOCATE_PAID_TIME_READ = `${READ_PERMISSION_PREFIX}advocate-paid-time`,
  ADVOCATE_PAID_TIME_READ_ALL = `${READ_PERMISSION_PREFIX}advocate-paid-time${ALL_PERMISSION_SUFFIX}`,
  LEAD_INFO_READ = `${READ_PERMISSION_PREFIX}lead-info`,
  LEAD_INFO_READ_ALL = `${READ_PERMISSION_PREFIX}lead-info${ALL_PERMISSION_SUFFIX}`,
  PIPELINE_READ_ALL = `${READ_PERMISSION_PREFIX}pipeline${ALL_PERMISSION_SUFFIX}`,
  WT_REQUEST_WRITE = `${WRITE_PERMISSION_PREFIX}wt-request`,
  ADVOCATE_STATUS_READ = `${READ_PERMISSION_PREFIX}advocate-status`,
  ADVOCATE_STATUS_READ_ALL = `${READ_PERMISSION_PREFIX}advocate-status${ALL_PERMISSION_SUFFIX}`,
  CALL_RECORDING_READ = `${READ_PERMISSION_PREFIX}call-recording`,
  CALL_RECORDING_READ_ALL = `${READ_PERMISSION_PREFIX}call-recording${ALL_PERMISSION_SUFFIX}`,
  AB_EXPERIMENT_READ = `${READ_PERMISSION_PREFIX}ab-experiment`,
  AB_EXPERIMENT_WRITE = `${WRITE_PERMISSION_PREFIX}ab-experiment`,
  ARC_FILTERED_VIEW_READ = `${READ_PERMISSION_PREFIX}arc-filtered-view`,
  ARC_FILTERED_VIEW_READ_ALL = `${READ_PERMISSION_PREFIX}arc-filtered-view${ALL_PERMISSION_SUFFIX}`,
  CALL_SCRIPT_READ_ALL = `${READ_PERMISSION_PREFIX}call-script${ALL_PERMISSION_SUFFIX}`,
  CALL_SCRIPT_WRITE_ALL = `${WRITE_PERMISSION_PREFIX}call-script${ALL_PERMISSION_SUFFIX}`,
  FINANCIAL_INFO_READ = `${READ_PERMISSION_PREFIX}financial`,
  FINANCIAL_INFO_READ_ALL = `${READ_PERMISSION_PREFIX}financial${ALL_PERMISSION_SUFFIX}`,
  ARC_CONFIGURATION_READ = `${READ_PERMISSION_PREFIX}arc-configuration`,
  ARC_CONFIGURATION_WRITE = `${WRITE_PERMISSION_PREFIX}arc-configuration`,
  ADVOCATE_USAGE_METRIC_WRITE = `${WRITE_PERMISSION_PREFIX}advocate-usage-metric`,
  ADMIN_TEST_WRITE = `${WRITE_PERMISSION_PREFIX}admin-test` /* Permission should never exist in Auth0 PROD!!! Only in dev and UAT */,
  EMERGENCY_WT_REQUEST_WRITE = `${WRITE_PERMISSION_PREFIX}emergency-wt-notification`,
  EMERGENCY_WT_REQUEST_READ = `${READ_PERMISSION_PREFIX}emergency-wt-notification`,
  ANSWER_EMERGENCY_WT_REQUEST_WRITE = `${WRITE_PERMISSION_PREFIX}answer-emergency-wt-notification`,
}

export const READ_PERMISSIONS = Object.values(APP_PERMS).filter((perm) => perm.startsWith(READ_PERMISSION_PREFIX));
export const READ_ALL_PERMISSIONS = READ_PERMISSIONS.filter((perm) => perm.endsWith(ALL_PERMISSION_SUFFIX));
export const READ_SINGLE_PERMISSIONS = READ_PERMISSIONS.filter((perm) => !perm.endsWith(ALL_PERMISSION_SUFFIX));
export const WRITE_PERMISSIONS = Object.values(APP_PERMS).filter((perm) => perm.startsWith(WRITE_PERMISSION_PREFIX));
/*
When updating this, also update the graphql schema, they are defined as an enum there
*/
export enum APP_ROLES {
  MENTOR = 'Mentor',
  ADMIN = 'Admin',
  ADVOCATE = 'Advocate',
  TRAINER = 'Trainer',
  DEVELOPER = 'Developer',
  BETA_TESTER = 'BetaTester',
  BOARD = 'Board Member',
}

export interface Organization {
  id: string;
  name: string;
  isActive: boolean;
  users: UserProfile[];
  version: string;
}

export interface ExternalUser {
  id: string;
  email: string;
  isActive: boolean;
  name: string;
  firstName: string;
  lastName: string;
  organization: string;
  version: string;
}

export interface UserSkill {
  skillName: string /* State or product (tied to carrier)*/;
  skillLevel: string;
  externalIdentifier: string;
  externalSkillId: string;
  version: string;
  isDeleted: boolean;
}

export interface UserTalkdeskInfo {
  externalIdentifier: string;
  userAttributes: {
    id: string;
    active: boolean;
    name: string /* State or product (tied to carrier) or WT*/;
    userProficiency: string;
  }[];
  ringGroups: string[];
}

export interface UserWTSkill {
  name: WT_SKILL;
}
export interface UserProfile {
  id: string;
  firstName: string;
  lastName: string;
  sfUserRoleId?: string;
  name: string;
  email: string;
  skills?: UserSkill[];
  licensedStates?: SUPPORTED_STATE[];
  wtLicensedStates?: SUPPORTED_STATE[];
  wtSkills?: UserWTSkill[];
  approvedProducts?: string[];
  talkdeskInfo?: UserTalkdeskInfo | null;
  talkdeskUserId?: string;
  version: string;
  timezone?: string;
  isActive: boolean;
  authorizations: {
    roles: APP_ROLES[];
    permissions: APP_PERMS[];
  };
  advocateLevel: REPRESENTATIVE_LEVEL | null;
  organization: string;
}

export type UiUserProfile = UserProfile & { isImpersonated: boolean };

export interface UserRoleTimeSerie {
  advocateLevel: REPRESENTATIVE_LEVEL | null;
  userRoleId: string;
  userId: string;
  email: string;
  timestamp: Date;
  eventId: string;
  name: string;
  firstName: string;
  lastName: string;
}

export type RepresentativeProfile = Pick<
  UserProfile,
  | 'advocateLevel'
  | 'email'
  | 'talkdeskUserId'
  | 'name'
  | 'licensedStates'
  | 'wtLicensedStates'
  | 'approvedProducts'
  | 'timezone'
  | 'wtSkills'
>;

export interface UserAppointment {
  externalIdentifier: string;
  ownerId: string;
  ownerName: string;
  startDateTime: DateTime;
  endDateTime: DateTime;
  subject: string;
  leadExternalIdentifier: string;
}

/* Remove on OWEB-1066 */
export const LEGACY_WT_SKILL = 'CNO Warm Transfer';

export const WT_SKILL_PREFIX = 'Warm Transfer';
export enum WT_SKILL {
  CNO = `${WT_SKILL_PREFIX}[Colonial Penn]`,
  LEGACY_CNO = LEGACY_WT_SKILL,
  POLICY_SCOUT = `${WT_SKILL_PREFIX}[Policy Scout]`,
  POLICY_SCOUT_CNO = `${WT_SKILL_PREFIX}[Policy Scout Colonial Penn]`,
  INTERNAL = `${WT_SKILL_PREFIX}[Internal]`,
  NEW_PARTNER = `${WT_SKILL_PREFIX}[New Partner]`,
}
